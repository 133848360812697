.inner-container {
  gap: 1.5rem;
  background: #4161df;
  padding: 2rem;
  border-radius: 10px;
  border: 6px solid #5d77d6;
  text-align: center;
}
.inner-container .primaryText {
  font-weight: 600;
  color: white;
}
.inner-container .secondaryText {
  color: rgb(243, 241, 241);
}
.inner-container .button {
  background: #5a73d7;
  border: 2px solid white;
  box-shadow: var(--shadow);
  border-radius: 10px;
}
